import QuantcastApi from '../apis/quantcast-api';
import IComponent from './IComponent';

const playableVendorId = 44;
const playableScriptId = 'playableScript';

const register = function (): void {
  QuantcastApi.getNonIABVendorConsents(playableVendorId, (vendorConsent: boolean) => {
    const playableScript = document.getElementById(playableScriptId);

    if (vendorConsent) {
      if (!playableScript) {
        const playableScriptTag = document.createElement('script');
        playableScriptTag.id = playableScriptId;
        playableScriptTag.type = 'text/javascript';
        playableScriptTag.async = true;
        playableScriptTag.src = 'https://popup.campaign.playable.com/script/c0dbfa42101110083a472a97715598dd/popup.min.js';
        document.head.appendChild(playableScriptTag);
      }
    } else if (playableScript) {
      playableScript.remove();
    }
  });
};

const playableComponent: IComponent = {
  name: 'playableComponent',
  register: register
};

export default playableComponent;
