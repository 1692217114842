const quantCastVersion = 2;

/**
 * Add event listener which triggers the callback function upon triggered event.
 * @param callback Callback function called when an event is triggered.
 * @link https://github.com/InteractiveAdvertisingBureau/GDPR-Transparency-and-Consent-Framework/blob/master/TCFv2/IAB%20Tech%20Lab%20-%20CMP%20API%20v2.md
 */
const addEventListener = function (callback: (tcData: QuantCast.TCData, success: boolean) => void) {
  tcfApi('addEventListener', (tcData: QuantCast.TCData, success: boolean) => {
    callback(tcData, success);
  });
};

/**
 * Gets consents for non IAB vendor.
 * @param callback Callback function called when an event is triggered.
 * @link https://github.com/InteractiveAdvertisingBureau/GDPR-Transparency-and-Consent-Framework/blob/master/TCFv2/IAB%20Tech%20Lab%20-%20CMP%20API%20v2.md
 */
const getNonIABVendorConsents = function (vendorId: number, callback: (vendorConsent: boolean) => void) {
  this.addEventListener((tcData: QuantCast.TCData, success: boolean) => {
    if (!success) {
      return;
    }

    if (!(tcData.eventStatus === 'useractioncomplete' || tcData.eventStatus === 'tcloaded')) {
      return;
    }

    tcfApi('getNonIABVendorConsents', (nonIabConsent: QuantCast.TCData, nonIabSuccess: boolean) => {
      const vendorConsent = nonIabSuccess
        && nonIabConsent.gdprApplies
        && nonIabConsent.nonIabVendorConsents
        && nonIabConsent.nonIabVendorConsents[vendorId];

      callback(!!vendorConsent);
    });
  });
};

const tcfApi = function (
  command: 'ping' | 'addEventListener' | 'removeEventListener' | 'getNonIABVendorConsents' | 'displayConsentUi' | 'getConfig',
  callback: (tcData: QuantCast.TCData, success: boolean) => void): void {
  // Check if the current environment is localhost
  const isLocalhost = window.location.hostname === 'localhost';

  if (!window.features.useQc) {
    callback(null, false);
  } else if (isLocalhost) {
    // Simulate the callback manually for localhost
    const simulatedTcData: QuantCast.TCData = {
      tcString: '',
      eventStatus: 'tcloaded',
      cmpStatus: '',
      isServiceSpecific: false,
      useNonStandardTexts: false,
      purposeOneTreatment: false,
      purpose: {
        consents: {},
        legitimateInterests: {}
      },
      vendor: {
        consents: {},
        legitimateInterests: {}
      },
      specialFeatureOptins: {},
      nonIabVendorConsents: {},
      gdprApplies: true,
    };

    // The index represents the vendor ID
    for (let index = 1; index <= 44; index++) {
      simulatedTcData.nonIabVendorConsents[index] = true;
    }

    const simulatedSuccess = true;

    // Invoke the callback with the simulated data
    callback(simulatedTcData, simulatedSuccess);
  } else {
    try {
      __tcfapi(command, quantCastVersion, (tcData: QuantCast.TCData, success: boolean) => {
        callback(tcData, success);
      });
    } catch (error) {
      // Preview throws an exception in test here because trackingscripts are disabled through cshtml.
      console.error('QuantCast', error);
    }
  }
};

/**
 * QuantCast API.
 */
export default {
  addEventListener,
  getNonIABVendorConsents
};
